<template>
    <el-dialog
        :title="data.btnText"
        :visible.sync="dialogVisible"
        width="600px"
        v-loading="loading"
        :before-close="cancelHandle"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
    >
        <el-row>
            <el-col :span="4">
                <div class="grid-content bg-purple">候选人：</div>
            </el-col>
            <el-col :span="20">
                <div class="grid-content bg-purple-light">{{ data.realName }}</div>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="4">
                <div class="grid-content bg-purple">面试职位：</div>
            </el-col>
            <el-col :span="20">
                <div class="grid-content bg-purple-light">{{ data.jobName }}</div>
                <div class="grid-content bg-purple-light">
                    <el-radio v-model="interviewForm.type" label="0">现场面试</el-radio>
                    <el-radio v-model="interviewForm.type" label="1">电话面试</el-radio>
                </div>
            </el-col>
        </el-row>

        <el-form :model="interviewForm" :rules="interviewFormRules" ref="interviewForm" label-width="0">

        <el-row>
            <el-col :span="4">
                <div class="grid-content bg-purple">面试时间：</div>
            </el-col>
            <el-col :span="20">
                <el-col :span="10">
                    <el-form-item prop="date">
                            <el-date-picker
                                v-model="interviewForm.date"
                                type="date"
                                value-format="yyyy-MM-dd"
                                :picker-options="datePickerOption"
                                placeholder="选择日期">
                            </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="10">
                    <el-form-item prop="time">
                        <el-time-select
                            v-model="interviewForm.time"
                            :picker-options="{
                                start: '00:00',
                                step: '00:15',
                                end: '24:00'
                            }"
                            value-format="HH:mm"
                            placeholder="选择时间">
                        </el-time-select>
                    </el-form-item>
                </el-col>
            </el-col>
        </el-row>

        <el-row v-if="interviewForm.type === '0'">
            <el-col :span="4">
                <div class="grid-content bg-purple">面试地点：</div>
            </el-col>
            <el-col :span="20">
                <el-form-item prop="address">
                <div class="grid-content bg-purple-light">
                    <el-input
                        type="textarea"
                        :autosize="{ minRows: 2, maxRows: 4}"
                        placeholder="请输入面试地址"
                        resize="none"
                        v-model="interviewForm.address"
                    ></el-input>
                </div>
                </el-form-item>
            </el-col>
        </el-row>

        <el-row v-if="interviewsList.length >= 2">
            <el-col :span="4">
                <div class="grid-content bg-purple">最终轮面试：</div>
            </el-col>
            <el-col :span="20">
                <div class="grid-content bg-purple-light">
                    <el-switch
                        v-model="interviewForm.isLastInterview"
                        >
                    </el-switch>
                    {{ interviewForm.isLastInterview? '是': '否' }}
                </div>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="4">
                <div class="grid-content bg-purple">联系人：</div>
            </el-col>
            <el-col :span="20">
                <div class="grid-content bg-purple-light">{{ data.headhunterRealName }}@{{ data.headhunterNickname }}
                </div>
            </el-col>
        </el-row>

        <!-- <el-row>
            <el-col :span="24">
                <div class="grid-content bg-purple">给候选人留言：</div>
            </el-col>
            <el-col :span="24">
                <el-form-item prop="remark">
                <el-input
                    type="textarea"
                    :rows="2"
                    placeholder="在给候选人邮件中那个附加留言（最多写50字）"
                    v-model="interviewForm.remark"
                    resize="none"
                ></el-input>
                </el-form-item>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="24">
                <el-checkbox v-model="interviewForm.shouldSendNotification">使用短信、邮件通知双方</el-checkbox>
            </el-col>
        </el-row> -->
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="cancelHandle">取消</el-button>
            <el-button type="primary" @click="confirmHandle">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import myService from '#/js/service/myService.js';
    import moment from 'moment'

    export default {
        name: "enter-sure-interview-dialog",
        components: {
        },
        data() {
            return {
                dialogVisible:false,
                loading:false,
                data: '',
                type: '0',
                date: '',
                time: '',
                address: '',
                remark: '',
                recommend: {},
                nextStatus: '',

                // shouldSendNotification: false,

                interviewForm: {
                    type: '0',
                    date: '',
                    time: '',
                    address: '',
                    remark: '',
                    // shouldSendNotification: false,
                    isLastInterview: false,
                },
                interviewFormRules: {
                    date: [
                        {
                            required: true,
                            message: '请选择日期',
                            trigger: 'blur'
                        }
                    ],
                    time: [
                        {
                            required: true,
                            message: '请选择时间',
                            trigger: 'blur'
                        }
                    ],
                    address: [
                        {
                            required: true,
                            message: '请输入面试地址',
                            trigger: 'blur'
                        }, {
                            min: 6,
                            max: 256,
                            message: '输入长度在6至256个字之内',
                            trigger: 'blur'
                        }
                    ],
                    remark: [
                        {
                            required: false,
                            message: '请输入面试附加留言',
                            trigger: 'blur'
                        },{
                            max: 50,
                            min: 4,
                            message: '输入长度在4至50个字之内',
                            trigger: 'blur'
                        }
                    ],

                },
                datePickerOption: {
                    disabledDate(time) {
                        return time.getTime() < new Date(moment().subtract(6, 'months').format('YYYY-MM-DD').replace(/\-/ig, '\/')).getTime();
                    }
                },
                interviewsList: [],
            }
        },
        computed: {
            unionId() {
                return this.$store.state.user.userInfo.unionId;
            },
        },
        created() {

        },
        mounted() {
            this.interviewForm.time = this.getNowTime();
        },
        methods: {
            // 获取当时时间的小时比如09:00  
            getNowTime() {
                let date = new Date();
                let hour = date.getHours();
                if(hour < 10) {
                    hour = `0${hour}`;
                }
                return `${hour}:00`;
            },

            getRecommendationInterviews() {
                myService
                    .recommendationInterviews(this.data.id)
                    .then(res => {
                        this.interviewsList = res?.filter(it => it.status == 0) || [];
                    });
            },
            // 将12:00:00 转换为 12:00
            formatTime(time) {
                if(time) {
                    let arr = time.split(':');
                    return arr[0] + ':' + arr[1];
                }
                return '';
            },
            
            // 获取安排面试数据
            getArrangeInterviewData() {
                this.loading = true;
                myService
                    .arrangeInterviewsInfo(this.data.id)
                    .then(res => {
                        if(res) {
                            this.interviewForm.date = res.interviewTime.split(' ')[0];
                            this.interviewForm.time = this.formatTime(res.interviewTime.split(' ')[1]);
                            this.interviewForm.address = res.interviewAddress;
                            this.interviewForm.type = String(res.interviewType);
                            this.interviewForm.remark = res.remark;
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },

            /**
             * 调用dialog
             * @param candidateInfo 候选人信息 必须包含以下信息：id,realName, jobName, headhunterRealName, headhunterNickname
             */
            showHandle(candidateInfo) {
                this.data = candidateInfo;
                if(candidateInfo.btnText != "进入面试") {
                    this.getArrangeInterviewData();
                }
                
                this.dialogVisible = true;
                this.getRecommendationInterviews();
            },
            /**
             * 确认按钮fn
             */
            confirmHandle() {
                // if(this.interviewForm.shouldSendNotification) {
                //     this.interviewFormRules.remark[0].required = true;
                // }
                this.$refs.interviewForm.validate((valId) => {
                    if(valId) {

                        // RecommendationId:"b13e03d8-b037-4816-93c8-b876857a4622"
                        // Status:4
                        // unionId:"aae103c8-d7f7-4c41-b032-2d37c71ac655"
                        if(this.data.btnText == "进入面试") {
                            const params = {
                                RecommendationId: this.data.id,
                                unionId: this.unionId,
                                Status: this.data.nextStatus,
                                arrangeInfo: {
                                    interviewTime: `${this.interviewForm.date} ${this.interviewForm.time}`,
                                    interviewAddress: this.interviewForm.address,
                                    remark: this.interviewForm.remark,
                                    interviewType: this.interviewForm.type,
                                },
                            };
                            this.loading = true;
                            myService
                                .recommendStage(params)
                                .then((res) => {
                                    if(res) {
                                        this.$emit('successFn', res);
                                    }
                                    this.dialogVisible = false;
                                })
                                .catch(err => {
                                    console.log(err);
                                })
                                .finally(() => {
                                    this.loading = false;
                                    this.clearForm();
                                });

                        } else { 
                            const params = {
                                recommendationId: this.data.id,
                                unionId: this.unionId,
                                arrangeInfo: {
                                    interviewTime: `${this.interviewForm.date} ${this.interviewForm.time}`,
                                    interviewAddress: this.interviewForm.address,
                                    remark: this.interviewForm.remark,
                                    interviewType: this.interviewForm.type,
                                },
                            };
                            this.loading = true;
                            myService
                                .toConfirmInterview(params)
                                .then((res) => {
                                    this.$emit('successFn', res);
                                    this.dialogVisible = false;
                                })
                                .finally(() => {
                                    this.loading = false;
                                    this.clearForm();
                                });
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });

            },
            cancelHandle() {
                this.clearForm();
                this.dialogVisible = false;
            },
            clearForm() {
                this.$refs.interviewForm.resetFields();
                this.interviewForm.type = '0';
                // this.interviewForm.shouldSendNotification = false;
                this.interviewForm.isLastInterview = false;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .modal-box-foot {
        .btn {
            width: 100px;
            min-width: auto !important;
            padding: 0;
            height: 36px;
            line-height: 36px;
            font-size: 14px;
            border: none;
        }

        .modal-box-cancel {
            margin-right: 50px;
            background: #eee;

            &:hover {
                background: #ddd;
            }
        }
    }

    .el-row {
        line-height: 40px;

        &:last-child{
            margin-bottom: 0;
        }
    }

    .el-date-editor--date{
        margin-right: 10px;
        width: 150px;
    }

    .el-date-editor--time-select{
        width: 130px;
    }
</style>
